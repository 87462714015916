import { AUTH_ROLES, AUTH_ADMIN_ROLES } from "@/constants";

export default [
  {
    path: process.env.VUE_APP_USE_LANG_IN_URL === "true" ? "/:lang([a-zA-Z][a-zA-Z])?" : "",
    component: { render: (h) => h("router-view") },
    navigationRoutesEntry: true,
    children: [
      {
        path: "",
        redirect: { name: "Dashboard" },
      },
      {
        /* Dashboard */ name: "Dashboard",
        path: "dashboard",
        component: () => import(/* webpackChunkName: "v-dashboard" */ "@/views/Dashboard"),
        meta: {
          auth: AUTH_ROLES,
          navi: {
            arrangement: "start",
            icon: "dashboard",
            exact: true,
          },
        },
      },
      {
        /* Tenants */ name: "Tenants",
        path: "tenants",
        component: () => import(/* webpackChunkName: "v-tenants" */ "@/views/Tenants"),
        meta: {
          auth: AUTH_ROLES,
          navi: {
            arrangement: "start",
            icon: "accounts",
          },
        },
        children: [
          {
            /* Tenants.Add */ name: "Tenants.Add",
            path: "add",
            component: () => import(/* webpackChunkName: "v-tenants-add" */ "@/views/Tenants/Add"),
            meta: {
              auth: AUTH_ADMIN_ROLES,
            },
          },
          {
            /* Tenants.DefaultRedirect */ path: ":tenantid",
            redirect: { name: "Tenants.Detail" },
          },
          {
            /* Tenants.Detail */ name: "Tenants.Detail",
            path: ":tenantid/detail",
            component: () => import(/* webpackChunkName: "v-tenants-detail" */ "@/views/Tenants/Detail"),
            meta: {
              navi: {
                arrangement: "start",
                icon: "location",
              },
            },
          },
          {
            /* Tenants.Users */ name: "Tenants.Users",
            path: ":tenantid/users",
            component: () => import(/* webpackChunkName: "v-tenants-users" */ "@/views/Tenants/Users"),
            meta: {
              navi: {
                arrangement: "start",
                icon: "user",
              },
            },
          },
          {
            /* Tenants.Notes */ name: "Tenants.Notes",
            path: ":tenantid/notes",
            component: () => import(/* webpackChunkName: "v-tenants-notes" */ "@/views/Tenants/Notes"),
            meta: {
              navi: {
                arrangement: "start",
                icon: "notes",
              },
            },
          },
          {
            /* Tenants.ChangeTracking */ name: "Tenants.ChangeTracking",
            path: ":tenantid/changetracking",
            component: () => import(/* webpackChunkName: "v-tenants-changetracking" */ "@/views/Tenants/ChangeTracking"),
            meta: {
              auth: AUTH_ADMIN_ROLES,
              navi: {
                arrangement: "start",
                icon: "history",
              },
            },
          },
        ],
      },
      {
        /* Locations */ name: "Locations",
        path: "locations",
        component: () => import(/* webpackChunkName: "v-locations" */ "@/views/Locations"),
        meta: {
          auth: AUTH_ROLES,
          navi: {
            arrangement: "start",
            icon: "locations",
          },
        },
        children: [
          {
            /* Users.Add */ name: "Locations.Add",
            path: "add",
            component: () => import(/* webpackChunkName: "v-locations-add" */ "@/views/Locations/Add"),
          },
          {
            /* Locations.DefaultRedirect */ path: ":locationid",
            redirect: { name: "Locations.Detail" },
          },
          {
            /* Locations.Detail */ name: "Locations.Detail",
            path: ":locationid/detail",
            component: () => import(/* webpackChunkName: "v-locations-detail" */ "@/views/Locations/Detail"),
            meta: {
              navi: {
                arrangement: "start",
                icon: "location",
              },
            },
          },
          {
            /* Locations.Addresses */ name: "Locations.Addresses",
            path: ":locationid/addresses",
            component: () => import(/* webpackChunkName: "v-locations-addresses" */ "@/views/Locations/Addresses"),
            meta: {
              navi: {
                arrangement: "start",
                icon: "addresses",
              },
            },
            children: [
              {
                /* Locations.Addresses.Add */ name: "Locations.Addresses.Add",
                path: "add",
                component: () => import(/* webpackChunkName: "v-users-locations-add" */ "@/views/Locations/Addresses/Add"),
              },
              {
                /* Locations.Addresses.Detail */ name: "Locations.Addresses.Detail",
                path: ":locationaddressid/detail",
                component: () => import(/* webpackChunkName: "v-locations-addresses-detail" */ "@/views/Locations/Addresses/Detail"),
                meta: {
                  navi: {
                    arrangement: "start",
                    icon: "addresses",
                  },
                },
              },
            ],
          },
          {
            /* Locations.Devices */ name: "Locations.Devices",
            path: ":locationid/devices",
            component: () => import(/* webpackChunkName: "v-locations-devices" */ "@/views/Locations/Devices"),
            meta: {
              navi: {
                arrangement: "start",
                icon: "b-sim",
              },
            },
          },
          {
            /* Locations.UserVisits */ name: "Locations.UserVisits",
            path: ":locationid/uservisits",
            component: () => import(/* webpackChunkName: "v-locations-uservisits" */ "@/views/Locations/Usertrackings"),
            meta: {
              navi: {
                arrangement: "start",
                icon: "location-users",
              },
            },
          },
          {
            /* Locations.QrCodes */ name: "Locations.Qrcodes",
            path: ":locationid/qrcodes",
            component: () => import(/* webpackChunkName: "v-locations-notes" */ "@/views/Locations/QrCodes"),
            meta: {
              navi: {
                arrangement: "start",
                icon: "arrow-down",
              },
            },
          },
          {
            /* Locations.Notes */ name: "Locations.Notes",
            path: ":locationid/notes",
            component: () => import(/* webpackChunkName: "v-locations-notes" */ "@/views/Locations/Notes"),
            meta: {
              navi: {
                arrangement: "start",
                icon: "notes",
              },
            },
          },
          {
            /* Locations.ChangeTracking */ name: "Locations.ChangeTracking",
            path: ":locationid/changetracking",
            component: () => import(/* webpackChunkName: "v-locations-changetracking" */ "@/views/Locations/ChangeTracking"),
            meta: {
              auth: AUTH_ADMIN_ROLES,
              navi: {
                arrangement: "start",
                icon: "history",
              },
            },
          },
        ],
      },
      {
        /* Users */ name: "Users",
        path: "users",
        component: () => import(/* webpackChunkName: "v-users" */ "@/views/Users"),
        meta: {
          auth: AUTH_ROLES,
          navi: {
            arrangement: "start",
            icon: "users",
          },
        },
        children: [
          {
            /* Users.Add */ name: "Users.Add",
            path: "add",
            component: () => import(/* webpackChunkName: "v-users-add" */ "@/views/Users/Add"),
          },
          {
            /* Users.DefaultRedirect */ path: ":userid",
            redirect: { name: "Users.Detail" },
          },
          {
            /* Users.Detail */ name: "Users.Detail",
            path: ":userid/detail",
            component: () => import(/* webpackChunkName: "v-users-detail" */ "@/views/Users/Detail"),
            meta: {
              navi: {
                arrangement: "start",
                icon: "user",
              },
            },
          },
          {
            /* Users.Logins */ name: "Users.Logins",
            path: ":userid/logins",
            component: () => import(/* webpackChunkName: "v-users-logins" */ "@/views/Users/Logins"),
            meta: {
              auth: AUTH_ADMIN_ROLES,
              navi: {
                arrangement: "start",
                icon: "accounts",
              },
            },
          },
          {
            /* Users.UserKeys */ name: "Users.UserKeys",
            path: ":userid/keys",
            component: () => import(/* webpackChunkName: "v-users-keys" */ "@/views/Users/UserKeys"),
            meta: {
              auth: AUTH_ADMIN_ROLES,
              navi: {
                arrangement: "start",
                icon: "b-key",
              },
            },
          },
          {
            /* Users.Services */ name: "Users.Services",
            path: ":userid/services",
            component: () => import(/* webpackChunkName: "v-users-services" */ "@/views/Users/Services"),
            meta: {
              navi: {
                arrangement: "start",
                icon: "services",
              },
            },
          },
          {
            /* Users.Locationtrackings */ name: "Users.Locationtrackings",
            path: ":userid/stats",
            component: () => import(/* webpackChunkName: "v-users-locations" */ "@/views/Users/Locationtrackings"),
            meta: {
              navi: {
                arrangement: "start",
                icon: "user-locations",
              },
            },
          },
          {
            /* Locations.QrCodes */ name: "Users.Qrcodes",
            path: ":userid/qrcodes",
            component: () => import(/* webpackChunkName: "v-locations-notes" */ "@/views/Users/QrCodes"),
            meta: {
              navi: {
                arrangement: "start",
                icon: "arrow-down",
              },
            },
          },
          {
            /* Users.Notes */ name: "Users.Notes",
            path: ":userid/notes",
            component: () => import(/* webpackChunkName: "v-users-notes" */ "@/views/Users/Notes"),
            meta: {
              navi: {
                arrangement: "start",
                icon: "notes",
              },
            },
          },
          {
            /* Users.ChangeTracking */ name: "Users.ChangeTracking",
            path: ":userid/changetracking",
            component: () => import(/* webpackChunkName: "v-users-changetracking" */ "@/views/Users/ChangeTracking"),
            meta: {
              auth: AUTH_ADMIN_ROLES,
              navi: {
                arrangement: "start",
                icon: "history",
              },
            },
          },
        ],
      },
      {
        /* Devices */ name: "Devices",
        path: "devices",
        component: () => import(/* webpackChunkName: "v-devices" */ "@/views/Devices"),
        meta: {
          auth: AUTH_ADMIN_ROLES,
          navi: {
            arrangement: "start",
            icon: "b-sim",
          },
        },
        children: [
          {
            /* Devices.Detail */ name: "Devices.Detail",
            path: ":deviceid/detail",
            component: () => import(/* webpackChunkName: "v-devices-detail" */ "@/views/Devices/Detail"),
            meta: {
              navi: {
                arrangement: "start",
                icon: "b-sim",
              },
            },
          },
          {
            /* Devices.ChangeTracking */ name: "Devices.ChangeTracking",
            path: ":deviceid/changetracking",
            component: () => import(/* webpackChunkName: "v-devices-changetracking" */ "@/views/Devices/ChangeTracking"),
            meta: {
              auth: AUTH_ADMIN_ROLES,
              navi: {
                arrangement: "start",
                icon: "history",
              },
            },
          },
        ],
      },
      {
        /* Orders */ name: "Orders",
        path: "orders",
        component: () => import(/* webpackChunkName: "v-orders" */ "@/views/Orders"),
        meta: {
          auth: AUTH_ROLES,
          navi: {
            arrangement: "start",
            icon: "cart-4",
          },
        },
        children: [
          {
            /* Orders.Detail */ name: "Orders.Detail",
            path: ":orderid/detail",
            component: () => import(/* webpackChunkName: "v-orders-detail" */ "@/views/Orders/Detail"),
            meta: {
              navi: {
                arrangement: "start",
                icon: "orders",
              },
            },
          },
          {
            /* Orders.ChangeTracking */ name: "Orders.ChangeTracking",
            path: ":orderid/changetracking",
            component: () => import(/* webpackChunkName: "v-orders-changetracking" */ "@/views/Orders/ChangeTracking"),
            meta: {
              auth: AUTH_ADMIN_ROLES,
              navi: {
                arrangement: "start",
                icon: "history",
              },
            },
          },
        ],
      },
      {
        /* OrderItems */ name: "OrderItems",
        path: "orderitems",
        component: () => import(/* webpackChunkName: "v-orders" */ "@/views/OrderItems"),
        meta: {
          auth: AUTH_ROLES,
          navi: {
            arrangement: "start",
            icon: "list-check",
          },
        },
        children: [],
      },
      {
        /* Products */ name: "Products",
        path: "products",
        component: () => import(/* webpackChunkName: "v-orders" */ "@/views/Products"),
        meta: {
          auth: AUTH_ROLES,
          navi: {
            arrangement: "start",
            icon: "orders",
          },
        },
        children: [
          {
            /* Orders.Detail */ name: "Products.Detail",
            path: ":productid/detail",
            component: () => import(/* webpackChunkName: "v-orders-detail" */ "@/views/Products/Detail"),
            meta: {
              navi: {
                arrangement: "start",
                icon: "orders",
              },
            },
          },
        ],
      },
      {
        /* Login */ name: "Login",
        path: "auth/login",
        component: () => import(/* webpackChunkName: "v-auth-login" */ "@/views/auth/Login"),
      },
      {
        /* Logout */ name: "Logout",
        path: "auth/logout",
        component: () => import(/* webpackChunkName: "v-auth-logout" */ "@/views/auth/Logout"),
      },
      {
        /* Forbidden (403) */ name: "Forbidden",
        path: "auth/403",
        component: () => import(/* webpackChunkName: "v-auth-forbidden" */ "@/views/auth/Forbidden"),
      },
      {
        /* Notfound (404) */ name: "Notfound",
        path: "404",
        component: () => import(/* webpackChunkName: "v-notfound" */ "@/views/Notfound"),
      },
      {
        name: "Refresh",
        path: "refresh",
        component: {
          render: (h) => h("div"),
          beforeRouteEnter(to, from, next) {
            next((vm) => {
              vm.$router.replace(from.path);
            });
          },
        },
      },
      {
        path: "*",
        redirect: { name: "Notfound" },
      },
    ],
  },
];
