import Vue from "vue";
import Vuex from "vuex";

import validators from "./modules/validators";

import configuration from "./modules/configuration";
import gui from "./modules/gui";
import profile from "./modules/profile";

import changetracking from "./modules/change-tracking";
import services from "./modules/services";

import locations from "./modules/locations";
import locationaddresses from "./modules/location-addresses";
import locationevents from "./modules/location-events";

import users from "./modules/users";
import useraddresses from "./modules/user-addresses";
import userlogins from "./modules/user-logins";
import userservices from "./modules/user-services";
import userlocations from "./modules/user-locations";

import orders from "./modules/orders";
import orderitems from "./modules/orderitems";
import products from "./modules/products";

import tenants from "./modules/tenants";
import usertenants from "./modules/user-tenants";

import devices from "./modules/devices";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    configuration,
    gui,
    profile,
    validators,

    changetracking,
    services,

    locations,
    locationaddresses,
    locationevents,

    users,
    useraddresses,
    userlogins,
    userservices,
    userlocations,

    orders,
    orderitems,

    products,

    tenants,
    usertenants,

    devices
  },
});
