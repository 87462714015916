import { STOREMODULE_ENDPOINT_MAP, STOREMODULE_ENDPOINTMODULE_MAP, TOASTER_OPTIONS } from "@/constants";

import Vue from "vue";
import http from "@/$plugins/http";

import base from "../base";
import IS from "../base/is";
import ENTITY from "../base/entity";

export default {
  namespaced: true,
  state: base.createState(IS.state, ENTITY.state, {}),
  getters: base.createGetters(IS.getters, ENTITY.getters, {}),
  mutations: base.createMutations(IS.mutations, ENTITY.mutations, {}),
  actions: base.createEntityActions(STOREMODULE_ENDPOINTMODULE_MAP.customer, STOREMODULE_ENDPOINT_MAP.usertenants, IS.actions, ENTITY.actions, {
    getAll(serverModuleName = "", serverEntityName = "", entityKey = "") {
      const entityGetAllAction = ENTITY.actions.getAll(serverModuleName, serverEntityName, entityKey);

      return function (store) {
        return entityGetAllAction(...arguments).then((response) => {
          store.dispatch("getRelationEntities");
          return response;
        });
      };
    },
    get(serverModuleName = "", serverEntityName = "", entityKey = "") {
      const entityGetAction = ENTITY.actions.get(serverModuleName, serverEntityName, entityKey);

      return function (store) {
        return entityGetAction(...arguments).then((response) => {
          store.dispatch("getRelationEntities");
          return response;
        });
      };
    },
    createInvitation(serverModuleName = "", serverEntityName = "", entityKey = "") {
      const isKey = `${serverEntityName}/createInvitation`;

      return function ({ state, getters, commit, dispatch }, options = {}) {
        commit("setSending", { key: isKey, sending: false });

        return new Promise((resolve) => {
          http({
            method: "put",
            url: `/${serverModuleName}/api/${serverEntityName}/${options.locationId}/invite`,
            data: options.invite,
          })
            .then((response) => {
              if (response.data.result.isExistingUser) {
                commit("setEntity", { key: entityKey, entity: response.data.result.userTenant });
                resolve(getters.getEntity(response.data.result.userTenant.id));
                dispatch("getRelationEntities");
              }

              if (Vue.$app.$bvToast) {
                Vue.$app.$bvToast.toast(
                  Vue.$app.$i18n.t("entity.apiactions.invited"),
                  Object.assign({}, TOASTER_OPTIONS.api.options.send, { title: `API ${serverEntityName}` })
                );
              }
            })
            .finally(() => {
              commit("setSending", { key: isKey, sending: false });
            });
        });
      };
    },
    getRelationEntities(serverModuleName = "", serverEntityName = "", entityKey = "") {
      return function ({ state, getters, commit, dispatch, rootGetters }) {
        const existingUserIds = rootGetters["users/getUnwrapped"].map((u) => u.id);
        const userIds = getters.getUnwrapped.filter((e) => !existingUserIds.includes(e.userId)).map((e) => e.userId);

        const existingTenantIds = rootGetters["tenants/getUnwrapped"].map((t) => t.id);
        const tenantIds = getters.getUnwrapped.filter((e) => !existingTenantIds.includes(e.tenantId)).map((e) => e.tenantId);

        dispatch("users/getEntities", userIds, { root: true });
        dispatch("tenants/getEntities", tenantIds, { root: true });
      };
    },
  }),
};
