import http from "@/$plugins/http";

import base from "../base";
import IS from "../base/is";
import auth from "@/$plugins/auth";

const ROLES_KEY = auth.options.rolesKey;

export default {
  namespaced: true,
  state: base.createState(IS.state, {
    user: null,
  }),
  getters: base.createGetters(IS.getters, {
    get: (state) => state.user,
    getRoles: (state) => (state.user || {})[ROLES_KEY],
    readOnlyTenantIds: (state) => (state.user || {}).readOnlyTenants || [],
    isReadOnlyTenant: (state, getters) => (tenantId) => tenantId && getters.readOnlyTenantIds.includes(tenantId),
    containsReadOnlyTenant: (state, getters) => (tenantIds) => (tenantIds || []).some((id) => getters.readOnlyTenantIds.includes(id)),
  }),
  mutations: base.createMutations(IS.mutations, {
    set(state, user = {}) {
      auth.user(user);
      state.user = user;
    },
  }),
  actions: base.createActions(IS.actions, {
    get({ state, getters, commit }) {
      commit("setLoading", { key: "get", loading: true, initial: state.user === null });

      return new Promise((resolve, reject) => {
        http({
          method: "get",
          url: "/customer/api/Profile",
        })
          .then((response) => {
            commit("set", response.data.result);
            resolve(getters.get);
          })
          .finally(() => {
            commit("setLoading", { key: "get", loading: false });
          });
      });
    },
  }),
};
