import { validators } from "@/$plugins/vuelidate";

export const STOREMODULE_ENDPOINTMODULE_MAP = {
  catalog: "catalog",
  customer: "customer",
  order: "order",
  orderitem: "orderitem",
  search: "search",
};

export const STOREMODULE_ENDPOINT_MAP = {
  changetracking: "ChangeTracking",
  services: "SubscriptionsAndServices",
  locations: "Locations",
  locationaddresses: "LocationAddress",
  locationevents: "LocationEvent",
  users: "Users",
  useraddresses: "UserAddress",
  userlogins: "UserLogins",
  userservices: "UserSubscriptionsAndServices",
  userlocations: "UserLocations",
  orders: "Orders",
  orderitems: "OrderItems",
  products: "Products",
  userlocationtrackings: "UserLocationTrackings",
  tenants: "Tenants",
  usertenants: "UserTenants",
  userkeys: "Keys",
  devices: "Devices"
};

export const FILE_ENDPOINT_DEFAULT_URLS = {
  upload: "/customer/api/Files/create",
  download: "/customer/api/Files/",
  delete: "/customer/api/Files/",
};

export const ROUTENAME_REQUIREDACTION_MAP = {
  locationaddresses: {
    routeName: "Locations.Addresses",
    requiredActionKey: "LocationAddress",
  },
  locationusers: {
    routeName: "Locations.Users",
    requiredActionKey: "UserLocations",
  },
  useraddresses: {
    routeName: "Users.Addresses",
    requiredActionKey: "UserAddress",
  },
  userlogins: {
    routeName: "Users.Logins",
    requiredActionKey: "UserLogins",
  },
  userservices: {
    routeName: "Users.Services",
    requiredActionKey: "UserSubscriptionsAndServices",
  },
  userlocationtrackings: {
    routeName: "Users.Locationtrackings",
    requiredActionKey: "UserSubscriptionsAndServices",
  },
};

export const COMPONENT_BASKET_MODULES = {
  // this is the base-model to define default states
  base: {
    isDefault: false,
    isPreloaded: false,
    showActionButtons: true,
    showSummary: true,
    showItemQuantity: true,
    showItemTotalprice: true,
    showItemDeliveryState: true,
    showItemQuantityButton: true,
    showItemServiceButtons: true,
    showItemActionButtons: true,
    getters: {},
    actions: {},
  },
};

export const COMPONENT_PRODUCTDELIVERYSTATE_STATES = {
  available: {
    serverId: "DeliveryStateAvailable",
    icon: "deliverystate-available",
    variant: "success",
  },
  some: {
    serverId: "DeliveryStateSomeAvailable",
    icon: "deliverystate-some",
    variant: "success",
  },
  inweeks: {
    serverId: "DeliveryStateAvailableInSomeWeeks",
    icon: "deliverystate-inweeks",
    variant: "warning",
  },
  unavailable: {
    serverId: "DeliveryStateUnavailable",
    icon: "deliverystate-unavailable",
    variant: "danger",
  },
  unknown: {
    serverId: "DeliveryStateUnknown",
    icon: "b-question-circle",
    variant: "dark",
  },
};

export const PRODUCT_ASSEMBLYTYPES = {
  assembled: {
    isDefault: true,
    type: "Assembled",
    associatedPrice: "price",
  },
  unassembled: {
    type: "Unassembled",
    associatedPrice: "priceUnassembled",
  },
};

export const PRODUCT_ASSEMBLYTYPE_DEFAULT = (Object.values(PRODUCT_ASSEMBLYTYPES).find((t) => t.isDefault) || Object.values(PRODUCT_ASSEMBLYTYPES)[0]).type;

export const SEARCH_FILTER_COMPONENT_TYPES = {
  text: {
    type: "text",
  },
  multiselect: {
    type: "multiselect",
  },
  daterange: {
    type: "daterange",
    controlKeys: {
      from: "from",
      till: "till",
    },
    minDate: "0001-01-01",
  },
  geopoint: {
    type: "geo_point",
    component: "geopoint",
  },
  unknown: {
    type: "unknown",
  },
};

export const USER_ROLES = {
  user: "User",
  tenantadmin: "TenantAdmin",
  admin: "Administrator",
  super: "SuperAdministrator",
};

export const AUTH_ROLES = [USER_ROLES.tenantadmin, USER_ROLES.admin, USER_ROLES.super];
export const AUTH_ADMIN_ROLES = [USER_ROLES.admin, USER_ROLES.super];

export const USER_APPROVALSTATES = {
  unapproved: {
    key: "Unapproved",
    setFromStates: [],
  },
  approved: {
    key: "Approved",
    icon: "lock-open",
    variant: "success",
    setFromStates: ["Unapproved", "Locked"],
  },
  locked: {
    key: "Locked",
    icon: "lock",
    variant: "danger",
    setFromStates: ["Approved"],
    needsConfirmation: true,
  },
};

export const USER_ADDRESS_TYPES = {
  default: {
    type: "Default",
    checkoutproperties: {
      key: "newUserAddress",
    },
  },
  delivery: {
    type: "DeliveryAddress",
    checkoutproperties: {
      key: "newDeliveryAddress",
      useBaseKeyBoolean: "deliveryAddressIsNewUserAddress",
      inheritKey: "newUserAddress",
    },
  },
  invoice: {
    type: "InvoiceAddress",
    checkoutproperties: {
      key: "newInvoiceAddress",
      useBaseKeyBoolean: "invoiceAddressIsDeliveryAddress",
      inheritKey: "newDeliveryAddress",
    },
  },
};

export const ENTITY_STATES = {
  default: {
    new: "New",
    ok: "Ok",
    openChangeRequests: "HaveOpenChangeRequests",
    pendingChangeRequests: "HavePendingChangeRequests",
  },
  /*
    define custom ENTITY_STATES by using the STOREMODULE_ENDPOINT_MAP keys
    e.g.
    changetracking: {
      new: 'Newer',
      ok: 'Okey',
      openChangeRequests: 'EntityHasOpenCRs',
      pendingChangeRequests: 'EntityHasPendingCRs,
      closedChangeRequests: 'EntityHasClosedCRs',
      ...
    }
  */
};

export const CHANGEREQUEST_STATES = {
  open: "Open",
  replaced: "Replaced",
  approved: "Approved",
  declined: "Declined",
  closed: "Closed",
};

export const CHANGETRACKING_STATES = {
  created: "Insert",
  updated: "Update",
  deleted: "Delete",
};

export const ORDER_STATES = {
  open: "Open",
  executed: "Executed",
  ordered: "Ordered",
  processed: "Processed",
  closed: "Closed",
  abandoned: "Abandoned",
};

export const ORDER_STATES_BADGES_VARIANTS = {
  open: "light",
  executed: "warning",
  ordered: "info",
  processed: "change",
  closed: "success",
  abandoned: "delete",
};

export const CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP = {
  input: {
    type: "StringValueAttribute",
    component: "control-input",
    baseValue: "",
  },
  localizedstring: {
    type: "LocalizedStringValueAttribute",
    component: "control-localized-input",
    baseValue: "",
  },
  integer: {
    type: "IntValueAttribute",
    component: "control-input",
    baseValue: "",
  },
  select: {
    type: "SingleSelectValueAttribute",
    component: "control-select",
    baseValue: "",
  },
  radio: {
    type: "SingleSelectRadioValueAttribute",
    component: "control-radio",
    baseValue: "",
  },
  singlerelation: {
    type: "SingleRelationValueAttribute",
    component: "control-select",
    baseValue: "",
  },
  textarea: {
    type: "MultiLineStringValueAttribute",
    component: "control-textarea",
    baseValue: "",
  },
  checkbox: {
    type: "MultiSelectValueAttribute",
    component: "control-checkbox",
    baseValue: [],
  },
  multirelation: {
    type: "MultiRelationValueAttribute",
    component: "control-multiselect",
    baseValue: [],
  },
  password: {
    type: "PasswordValueAttribute",
    component: "control-input",
    baseValue: "",
    controlType: "password",
    validators: ["requiredValidator"],
    /*
      https://docs.microsoft.com/en-us/aspnet/core/security/authentication/identity-configuration?view=aspnetcore-5.0#password
      Passord Regex:
      ^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]))([a-zA-Z\d]|[^a-zA-Z\d\s]){6,}$
      1 lowercase
      1 uppercase
      1 digit
      1 nonalphanumeric => ~`!@#$%^&*()_-+={}[]|\:;<,>.?/
        but no space-characters \s => space\r\n\t\f\v
      6 digits or longer
    */
    customValidators: {
      lowercase: {
        validation: (value) => /^(?=.*[a-z]).+$/.test(value),
      },
      uppercase: {
        validation: (value) => /^(?=.*[A-Z]).+$/.test(value),
      },
      digit: {
        validation: (value) => /^(?=.*\d).+$/.test(value),
      },
      nonalphanumeric: {
        validation: (value) => /^(?=.*[^a-zA-Z\d\s]).+$/.test(value),
      },
      nonundefined: {
        validation: (value) => /^([a-zA-Z\d]|[^a-zA-Z\d\s])+$/.test(value),
      },
      minLength: {
        validation: validators.minLength,
        parameters: 6,
      },
    },
  },
  files: {
    type: "MultiFileValueAttribute",
    component: "control-files",
    baseValue: [],
    requiresHardChange: true,
  },
  boolean: {
    type: "BooleanValueAttribute",
    component: "control-checkbox",
    baseValue: false,
  },
  datetime: {
    type: "DateTimeValueAttribute",
    component: "control-datepicker",
    baseValue: "",
  },
  unknown: {
    type: "UnknownValueAttribute",
    component: "control-unknown",
    baseValue: "",
  },
};

export const CONTROL_DISCRIMINATOR_TO_COMPONENT_MAP = Object.keys(CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP).reduce(
  (types, tKey) =>
    Object.assign(types, {
      [CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP[tKey].type]: Object.assign({}, CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP[tKey], { type: tKey }),
    }),
  {}
);

export const CONTROL_CHECK_TO_DISCRIMINATOR_MAP = {
  equal: {
    type: "VisibleWhenEqual",
    check: (a, b) => a === b,
  },
};

export const CONTROL_DISCRIMINATOR_TO_CHECK_MAP = Object.keys(CONTROL_CHECK_TO_DISCRIMINATOR_MAP).reduce(
  (checks, cKey) =>
    Object.assign(checks, { [CONTROL_CHECK_TO_DISCRIMINATOR_MAP[cKey].type]: Object.assign({}, CONTROL_CHECK_TO_DISCRIMINATOR_MAP[cKey], { type: cKey }) }),
  {}
);

export const TOASTER_OPTIONS = {
  api: {
    id: "ApiToasts",
    options: {
      error: {
        toaster: "ApiToasts",
        variant: "danger",
        title: "API Error",
        noCloseButton: true,
        isStatus: true,
        appendToast: true,
        autoHideDelay: 4000,
      },
      send: {
        toaster: "ApiToasts",
        variant: "success",
        noCloseButton: true,
        isStatus: true,
        appendToast: true,
        autoHideDelay: 1500,
      },
    },
  },
};

export const CLASS_TABLE_GLOBAL_FIELD_EXCLUDES = ["id", "_hasDetails", "_isDetails", "_showDetails", "_showDetailsHook", "_isHidden", "_rowVariant"];

export const CLASS_TABLE_FIELD_KEY_ALL = "~all~";

export const CLASS_TABLE_FIELD_KEY_UNKNOWN = "~unknown~";

export const CLASS_TABLE_FIELD_KEYS_UNSORTABLE = ["$toggleDetails", "$kill"];

export const ENTITY_STATEBADGE_VARIANTS = {
  new: "new",
  ok: "light",
  openChangeRequests: "warning", // registerd, but untouched (show Buttons)
  pendingChangeRequests: "warning", // accepted, but not DIAS confirmed (do not show buttons)
};

export const CHANGETRACKING_STATEBADGE_VARIANTS = {
  created: "new",
  updated: "change",
  deleted: "delete",
};

export const ENTITY_CONTROL_HIDDEN_BY_PROPERTY_KEYS = ["hidden"];

export const PAGINATION_INITIAL_VALUES = {
  page: 1,
  pageSize: 50,
};

export const REQUIRED_ACTIONS_INITIAL_VALUES = {
  page: 1,
  pageSize: 10,
};

export const SEARCH_INITIAL_VALUES = {
  page: 1,
  pageSize: 30,
};

export const SEARCH_FILTER_EXPANDED_DEFAULT = true;

export const SEARCH_FILTER_EMPTY_CONTROL_VALUES = {
  text: "",
  multiselect: false,
  daterange: null,
};

export const SEARCH_FILTER_URL_PARAMETER_SEPARATOR = "++";

export const COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP = {
  separator: {
    type: "SeparatorCosts",
    component: "summary-separator",
  },
  subtotal: {
    type: "ProductCosts",
    component: "summary-price",
  },
  additional: {
    type: "AdditionalCosts",
    component: "summary-price",
  },
  subtractional: {
    type: "SubtractionalCosts",
    component: "summary-price",
    attrs: {
      class: "text-success",
    },
  },
  vat: {
    type: "VatCosts",
    component: "summary-price",
  },
  total: {
    type: "TotalCosts",
    component: "summary-price",
    attrs: {
      showCurrency: true,
      total: true,
    },
  },
};

export const COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP = Object.keys(COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP).reduce(
  (types, tKey) =>
    Object.assign(types, {
      [COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP[tKey].type]: Object.assign({}, COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP[tKey], { type: tKey }),
    }),
  {}
);

export const COMPONENT_CONTROL_ENTITYPROPERTY_ROWGROUPS = [
  {
    entityName: "User",
    firstname: 6,
    lastname: 6,
  },
  {
    entityName: "User",
    company: 6,
    language: 6,
  },
  {
    entityName: "User",
    phoneMobile: 6,
    phoneBusiness: 6,
  },
  {
    street: 6,
    addition: 6,
  },
  {
    zip: 4,
    city: 8,
  },
];
